import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Recaptcha from "react-recaptcha"
import { graphql, Link, navigate } from "gatsby"
// Components
import { SectionTitle, Input } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
import { Seo } from "../components/seo"
import { NewRecruit } from "../crud/createRegister"
import { useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import useSSR from "use-ssr"
const Recruit = ({ data }) => {
  const { isBrowser } = useSSR()
  const [registerRecruit, setRegisterRecruit] = useState({
    FullName: "",
    Email: "",
    ConfirmEmail: "",
    PhoneNumber: "",
    Country: "",
    Motivation: "",
    messageError: {},
    isVerified: false,
  })
  const [loading, setLoading] = useState(false)
  const { messageError } = registerRecruit
  const emailRegex = RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)

  const handleChange = e => {
    const { name, value } = e.target
    setRegisterRecruit(prevState => ({
      ...prevState,
      [name]: value,
    }))

    let errors = registerRecruit.messageError
    switch (name) {
      case "FullName":
        errors.FullName =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break

      case "Email":
        errors.Email =
          value === ""
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : "" || emailRegex.test(value)
            ? ""
            : "これは有効なメールではありません"
        break

      case "ConfirmEmail":
        errors.ConfirmEmail =
          value !== registerRecruit.Email
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : ""
        break
      case "Country":
        errors.Country =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      case "Motivation":
        errors.Motivation =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      default:
        break
    }
  }

  const [addNewRecruit] = useMutation(NewRecruit, {
    onCompleted: () => {
      navigate("/thankyou")
    },
    onError: () => {
      setLoading(false)
      toast.error("サーバーエラー")
    },
  })

  const handleRegisterRecruit = () => {
    addNewRecruit({
      variables: {
        input: {
          data: {
            FullName: registerRecruit.FullName,
            Email: registerRecruit.Email,
            PhoneNumber: registerRecruit.PhoneNumber,
            Country: registerRecruit.Country,
            Motivation: registerRecruit.Motivation,
          },
        },
      },
    })
  }
  const [captchaVerify, setCaptchaVerify] = useState(false)
  const recaptchaVerify = response => {
    if (response) {
      setRegisterRecruit(prevState => ({
        ...prevState,
        isVerified: true,
      }))
    } else {
      setCaptchaVerify(true)
      setTimeout(() => {
        setCaptchaVerify(false)
      }, 3000)
    }
  }
  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data).forEach(val => {
      val === "" && (valid = false)
    })
    return valid
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (registerRecruit.isVerified) {
      if (
        registerRecruit.FullName !== "" &&
        registerRecruit.Email !== "" &&
        registerRecruit.ConfirmEmail !== "" &&
        registerRecruit.Country !== "" &&
        registerRecruit.Motivation !== ""
      ) {
        if (formValidate(registerRecruit.messageError, registerRecruit)) {
          setLoading(true)
          handleRegisterRecruit()
        } else {
          toast.error("フォームが正しくありません")
        }
      } else {
        toast.error("入力内容を再度ご確認ください。")
      }
    } else {
      recaptchaVerify()
    }
  }

  const images = data.allFile.nodes
  return (
    <section className="container recluit">
      <div className="row">
        <div className="col-lg-9 col-lg-9">
          <div className="recluit__information">
            <SectionTitle>
              QUICK GLOBAL MEXICO, S.A. DE C.V.での採用に関する情報
            </SectionTitle>
            <hr className="u-line-bottom" />
            <GatsbyImage
              image={getImage(images[0].childImageSharp?.gatsbyImageData)}
              alt=""
            />
            <h2>
              <i className="fas fa-h2    "></i>採用情報
            </h2>
            <h3 className="recluit__information--style_h3">
              我々クイックグローバルメキシコは、一緒に働く仲間を募集中です！
            </h3>
            <p>
              当社は転職支援・人材紹介・人材採用支援を行っている東証プライム市場上場企業クイックのグループ会社です{" "}
              <br /> <br />
              日本で約40年にわたって人材ビジネスに取り組んできた株式会社クイックのメキシコ法人として、メキシコに進出している日系企業へ向けて人材紹介事業を行っています。{" "}
              <br /> <br />
              メキシコで就職支援・在メキシコ日系企業の採用活動を一緒にお手伝いしませんか？
              <br />
              お客様の人生の重要なターニングポイントに関わり、国境を越えて人と人、人と企業を繋げるやりがいのあるお仕事です。
              <br /> <br />
              スペイン語か英語どちらかを日常会話レベルでご使用頂ければ、活躍の場をご用意致します。語学において足りない部分は働きながら少しずつ身に付けていただければと考えています。{" "}
              <br /> <br />
              また、現在メキシコでの就労ビザは取得しやすい状況であるため、メキシコ国外の方も大歓迎です。{" "}
              <br /> <br />
              「こんな方にオススメです！」 <br /> <br />
              <ul className="recluit__list">
                <li>
                  海外で働きたいという思いは強いが、チャンスが今までなかった。
                </li>
                <li>語学力に不安があり一歩を踏み出せなかった。</li>
                <li>
                  スペイン語は勉強していたので、実際にスペイン語圏で働いてみたかった。
                </li>
                <li>
                  日本でも仕事したことはあるが、日本以外の国で一度仕事がしてみたかった。
                </li>
                <li>
                  既にメキシコで就職して働いているが、メキシコ国内での転職を考え中。
                </li>
              </ul>
              <br />
              皆様からのご応募をお待ちしています。
            </p>
            <h2>募集の背景</h2>
            <p>
              QUICK GLOBAL MEXICO, S.A. DE
              C.V.は設立して間もない会社ですが、既に多数の日系企業からの引き合いを頂いており、今後も事業を拡大していきたいと考えています。
              <br />
              まだまだ未完成な部分も多いですが、そこを面白いと感じられる方。一緒に組織運営に関わり、より良い組織運営を一緒に考えてくれるバイタリティのある方のご応募をお待ちしております。
            </p>
            <h3>仕事の内容(営業・キャリアカウンセラー)</h3>
            <p>
              メキシコ進出中の日系企業への人材紹介営業および、メキシコ勤務希望者のキャリアカウンセリング
              <br />
              <br />
              【具体的には】 <br />
              <ul className="recluit__list">
                <li>
                  在メキシコの日系企業に訪問し、募集情報のヒアリングや転職希望者のご紹介を行う営業活動。
                </li>
                <li>
                  転職希望者と面談し、お仕事のご紹介やキャリアカウンセリングの実施。
                </li>
              </ul>
            </p>
            <h3>対象となる方</h3>
            <p>
              大卒以上、35歳以下◎英語orスペイン語日常会話レベル以上
              <br /> <br />
              【具体的には】
              <ul className="u-list-style-none">
                <li>■ 人と関わり、人の役に立てる仕事をしたい方</li>
                <li>■ 事業拡大に意欲的な方</li>
                <li>
                  ◎人材紹介で、登録希望者の面談を経験したことのある方優遇。
                </li>
                <li>◎接客・営業経験がある方優遇。</li>
                <li>◎要普通免許</li>
                <li>※新卒採用は行っていません</li>
              </ul>
            </p>

            <h3>詳細情報</h3>
            <p>
              <b>勤務地</b>
              ：メキシコ（アグアスカリエンテス州　※非常に治安のいい都市です）
              <br />
              <b>勤務時間</b>：9:00～18:00
              <br />
              <b>給与</b>：月給3万ペソ以上
              <br />
              <b>待遇・福利厚生</b>
              ：賞与有、インセンティブ、社会保険、医療保険、語学取得サポート有、日本研修有（応相談）、クリスマスボーナス有、車両購入サポート有、交通費支給（ガソリン代）、携帯電話・PC貸与有、語学資格取得支援制度有、ビザサポート有
              <br />
              <b>休日・休暇</b>
              ：完全週休2日制（土・日・祝）、有給休暇、長期休暇
            </p>
            <p>
              【参考情報】
              <br />
              <Link to="/info/mexico-job-7-reason">
                今、メキシコの求人が熱い！７つの理由
              </Link>
              <br />
              <Link to="/info/aguascalientes">
                メキシコの街に住む　アグアスカリエンテス編
              </Link>
            </p>
            <h2>応募方法</h2>
            <p>下記の応募フォームより必要事項をご記入の上、送信ください。</p>
          </div>
          <form className="employers__form-contact" onSubmit={handleSubmit}>
            <h2>クイックグローバルメキシコへのお問い合わせ</h2>
            <Input>
              <label htmlFor="お名前">
                <p className="form-label">お名前 </p>
                <span>必須</span>
              </label>
              <input
                type="text"
                id="お名前"
                name="FullName"
                onChange={handleChange}
                onBlur={handleChange}
              />
              {messageError.FullName && (
                <span className="field-validation">
                  {messageError.FullName}
                </span>
              )}
            </Input>
            <Input>
              <label htmlFor="メールアドレス">
                <p className="form-label">メールアドレス </p>
                <span>必須</span>
              </label>
              <input
                type="text"
                id="メールアドレス"
                name="Email"
                onChange={handleChange}
                onBlur={handleChange}
              />
              {messageError.Email && (
                <span className="field-validation">{messageError.Email}</span>
              )}
            </Input>
            <Input>
              <label htmlFor="メールアドレスの再入力">
                <p className="form-label">メールアドレスの再入力 </p>
                <span>必須</span>
              </label>
              <input
                type="text"
                id="メールアドレスの再入力"
                name="ConfirmEmail"
                onChange={handleChange}
                onBlur={handleChange}
              />
              {messageError.ConfirmEmail && (
                <span className="field-validation">
                  {messageError.ConfirmEmail}
                </span>
              )}
            </Input>
            <Input>
              <label htmlFor="電話番号">
                <p className="form-label">電話番号</p>
              </label>
              <input
                type="text"
                id="電話番号"
                name="PhoneNumber"
                onChange={handleChange}
                onBlur={handleChange}
              />
            </Input>
            <Input>
              <label htmlFor="在住国">
                <p className="form-label">在住国 </p>
                <span>必須</span>
              </label>
              <input
                type="text"
                id="在住国"
                name="Country"
                onChange={handleChange}
                onBlur={handleChange}
              />
              {messageError.Country && (
                <span className="field-validation">{messageError.Country}</span>
              )}
            </Input>
            <Input>
              <label htmlFor="Motivation">
                <p className="form-label">
                  クイックグローバルメキシコの求人への応募動機、きっかけ、その他
                </p>
                <span>必須</span>
              </label>
              <textarea
                type="text"
                id="Motivation"
                name="Motivation"
                onChange={handleChange}
                onBlur={handleChange}
              />
              {messageError.Motivation && (
                <span className="field-validation">
                  {messageError.Motivation}
                </span>
              )}
            </Input>
            <div className="captcha-style">
              {isBrowser && (
                <Recaptcha
                  render="explicit"
                  sitekey="6Lf4VfAbAAAAAO1Fu6ZqpDdvbs929UMuN04vwA3A"
                  name="isVerify"
                  verifyCallback={recaptchaVerify}
                />
              )}

              {captchaVerify ? (
                <span className="field-validation-captcha">
                  Please verify that you are not a robot
                </span>
              ) : (
                ""
              )}
            </div>
            <p>
              当社の
              <Link
                className="btn-terms-condition"
                href="/termsofuse"
                target="_blank"
              >
                利用規約・個人情報保護方針
              </Link>
              に同意の上、お申込みください。
            </p>
            <div className="u-text-center">
              {loading === false ? (
                <input type="submit" value="申し込む" className="btn-submit" />
              ) : (
                <p className="buttonload">
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                  &nbsp;&nbsp;少々お待ちください
                </p>
              )}
            </div>
          </form>
          <Contact lang={"ja"}/>
        </div>
        <WrapperRight />
      </div>
    </section>
  )
}

export default Recruit

export const queryImage = graphql`
  {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)|(webp)/" }
        relativeDirectory: { eq: "others" }
      }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            width: 1800
            quality: 100
            webpOptions: { quality: 100 }
            formats: [WEBP, AUTO]
          )
        }
      }
    }
  }
`

export const Head = ({ data }) => {
  const images = data.allFile.nodes
  return (
    <Seo
      title="QUICK GLOBAL MEXICO, S.A. DE C.V.での採用に関する情報"
      description="メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントサービス。メキシコ国内の転職だけでなく、日本からの転職もサポート。"
      url={`https://www.919mexico.com/recruit`}
      image={images[0].childImageSharp?.gatsbyImageData.images.fallback.src}
    />
  )
}
